import { baseApi2 } from '@/store/queries';
import { programsEndpoints } from '@/store/domains/resources/programs';
import { ProgramTemplate } from '@/types/api/programs';
import { ProgramInfoByProgramOfferingIdTemplate } from '@/types/api/student/program';

export const programsApi = baseApi2.injectEndpoints({
  endpoints: (build) => ({
    getTemplateByCodeAndVersion:
      build.query<ProgramTemplate, { code: string, version: string }>(
        { query: ({ code, version }) => ({ url: programsEndpoints.getTemplateByCodeAndVersion(code, version), method: 'GET' }) },
      ),
    getTemplatesByCodesAndVersions:
      build.query<ProgramTemplate[], {
        codesAndVersions: Array<{ code: string, version: string }>
      }>({
        async queryFn(
          { codesAndVersions },
          api,
          extraOptions,
          baseQuery,
        ) {
          if (!codesAndVersions || codesAndVersions.length === 0) {
            return { data: [] };
          }
          const programsDataPromises = codesAndVersions.map(
            async (program: { code: string, version: string }) => {
              const response = await baseQuery({ url: programsEndpoints.getTemplateByCodeAndVersion(program.code, program.version), method: 'GET' });
              return response.data;
            },
          );
          const programsData = await Promise.all(programsDataPromises) as ProgramTemplate[][];
          const programsList = programsData.map((i) => i[0]);
          return { data: programsList };
        },
      }),
    getProgramInfoByProgramOfferingId:
      build.query<ProgramInfoByProgramOfferingIdTemplate, { programOfferingId: string }>(
        { query: ({ programOfferingId }) => ({ url: programsEndpoints.getProgramInfoByProgramOfferingId(programOfferingId), method: 'GET' }) },
      ),
  }),
});

export const {
  useGetTemplateByCodeAndVersionQuery,
  useGetTemplatesByCodesAndVersionsQuery,
  useGetProgramInfoByProgramOfferingIdQuery,
} = programsApi;
