import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Image from 'next/image';
import Icon from '@/components/atoms/icon';
import {
  useGetAvatarQuery,
  useGetOfficialNameQuery,
  useGetPersonByIdQuery,
} from '@/store/queries/person';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import {
  StyledAvatarContainer,
  StyledAvatarLink,
  StyledAvatar,
  StyledAvatarNameContainer,
  StyledAvatarName,
  StyledIrnContainer,
  StyledIrnLetters,
  StyledIrnText,
  StyledIrnCopiedText,
} from '@/components/molecules/header-avatar/HeaderAvatar.styles';
import { useClickEventTracking } from '@/hooks/useClickEventTracking';

function HeaderAvatar({
  personId,
  parentComponentName,
  profileLink,
  parentId,
}) {
  const personIdToFetch = personId || getPersonIdFromSessionStorage();

  const { data: { issuerId } = {} } = useGetPersonByIdQuery(
    { personId: personIdToFetch },
    { skip: !personIdToFetch },
  );

  const { data: { imageContentType, encodedImageBytes } = {} } = useGetAvatarQuery(
    { personId: personIdToFetch },
    { skip: !personIdToFetch },
  );

  const { data: { firstName, lastName } = {} } = useGetOfficialNameQuery(
    { personId: personIdToFetch },
    { skip: !personIdToFetch },
  );

  const ariaLabel = issuerId ? `${firstName} ${lastName} IRN ${issuerId}` : `${firstName} ${lastName}`;
  const trackClick = useClickEventTracking();
  const componentName = 'HeaderAvatar';

  const [copiedInterval, setCopiedInterval] = useState(null);
  const handleCopyClick = () => {
    const interval = setInterval(() => {
      clearInterval(copiedInterval);
      setCopiedInterval(null);
    }, 2000);
    setCopiedInterval(interval);
  };

  let avatarContent;
  if (imageContentType && encodedImageBytes) { // Encoded Image
    avatarContent = (
      <Image
        src={`data:${imageContentType};base64,${encodedImageBytes}`}
        alt="Profile avatar picture"
        layout="fill"
        aria-hidden="true"
      />
    );
  } else if (firstName && lastName) { // Initials
    avatarContent = `${firstName.substring(0, 1)}${lastName.substring(0, 1)}`;
  } else { // Loading Icon
    avatarContent = (
      <Icon
        id={`icon-avatar-placeholder-${parentId}`}
        icon="icon-phoenix-head"
        alt="phoenix-head"
      />
    );
  }

  return (
    <StyledAvatarContainer>
      <StyledAvatarLink
        href={profileLink}
        aria-label={ariaLabel}
        onClick={() => trackClick(
          parentId === 'header'
            ? `${parentComponentName}_${componentName}_AvatarLink`
            : `${parentComponentName}_IssuerIdLink`,
          [{ profileLink }, { issuerId }, { text: 'PROFILE' }],
        )}
      >
        <StyledAvatar>
          {avatarContent}
        </StyledAvatar>
        <StyledAvatarNameContainer>
          <StyledAvatarName>
            {firstName}
            {' '}
            {lastName}
          </StyledAvatarName>
        </StyledAvatarNameContainer>
      </StyledAvatarLink>
      {issuerId && (
      <StyledIrnContainer
        onClick={() => trackClick(
          parentId === 'header'
            ? `${parentComponentName}_${componentName}_AvatarLink`
            : `${parentComponentName}_CopyIssuerIdLink`,
          [{ profileLink }, { issuerId }, { text: 'IRN' }],
        )}
      >
        <StyledIrnText>
          <StyledIrnLetters>
            <span>IRN</span>
          </StyledIrnLetters>
          &nbsp;
          {issuerId}
            &nbsp;
          <CopyToClipboard text={issuerId} onCopy={handleCopyClick}>
            <Icon
              id={`${parentId}-icon-copy`}
              icon="icon-copy"
              ariaLabel="copy IRN"
              aria-label="copy IRN"
            />
          </CopyToClipboard>
          <StyledIrnCopiedText shouldShow={copiedInterval} aria-live="assertive">Copied!</StyledIrnCopiedText>
        </StyledIrnText>
      </StyledIrnContainer>
      )}
    </StyledAvatarContainer>
  );
}

HeaderAvatar.propTypes = {
  personId: PropTypes.string.isRequired,
  parentId: PropTypes.string.isRequired,
  profileLink: PropTypes.string,
  parentComponentName: PropTypes.string,
};

HeaderAvatar.defaultProps = {
  parentComponentName: '',
  profileLink: '',
};

export default HeaderAvatar;
