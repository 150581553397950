import { DateTime } from 'luxon';
import { getItem as getItemFromSessionStorage } from '@/helpers/session-storage';
import { DATE_OVERRIDE_KEY } from '@/helpers/session-storage/dateTimeOverride';

const PHOENIX_TZ = 'America/Phoenix';

const toOrdinal = (number: number) => {
  const suffix = ['th', 'st', 'nd', 'rd'];
  const index = number % 100;
  return number + (suffix[(index - 20) % 10] || suffix[index] || suffix[0]);
};

const toDateTime = (
  date: string,
  zone: string = PHOENIX_TZ,
) => DateTime.fromISO(date, { zone: PHOENIX_TZ }).setZone(zone);

const toDateTimeFromMs = (millis: number) => DateTime.fromMillis(millis, { zone: PHOENIX_TZ });

const toDateString = (date: string, format: string = 'MM/dd/yyyy') => toDateTime(date).toFormat(format);

const toDateStringWithTime = (date: string) => toDateTime(date).toFormat('L/d/yyyy, h:mm a');

const toSpokenDateFromISO = (dateISO: string) => {
  const date = toDateTime(dateISO);
  return `${date.toFormat('cccc, MMMM')} ${toOrdinal(date.day)}`;
};
const convertDateFormat = (date: string, fromFormat: string, toFormat:string) => {
  const convertedDate = DateTime.fromFormat(date, fromFormat, { setZone: true });
  const dateInConvertedFormat = convertedDate.toFormat(toFormat);
  return dateInConvertedFormat;
};

const convertFromISOToFormat = (ISOdateString: string, toFormat:string) => {
  const convertedDate = DateTime.fromISO(ISOdateString, { setZone: true });
  const dateInConvertedFormat = convertedDate.toFormat(toFormat);
  return dateInConvertedFormat;
};
const convertFromFormatToISO = (date: string, fromFormat: string) => {
  const convertedDate = DateTime.fromFormat(date, fromFormat, { setZone: true });
  const dateInConvertedFormat = convertedDate.toISO();
  return dateInConvertedFormat;
};

const getCurrentDate = (timezone = PHOENIX_TZ) => {
  const dateOverrideISOString = getItemFromSessionStorage(DATE_OVERRIDE_KEY);
  const today = DateTime.now().setZone(timezone);
  if (dateOverrideISOString) {
    const overriddenDate = toDateTime(dateOverrideISOString);
    if (overriddenDate.isValid) {
      return overriddenDate.setZone(timezone);
    }
  }
  return today;
};

type UnitType = 'milliseconds' | 'seconds' | 'minutes' | 'hours' | 'days' | 'months' | 'years';

// Returns a positive number if date1 > date2, negative number if date1 < date2
const diffFromDates = (date1: string, date2: string, unit: UnitType = 'days') => {
  const dateTime1 = toDateTime(date1);
  const dateTime2 = toDateTime(date2);
  const diff = (dateTime1.diff(dateTime2, unit)).toObject();
  return diff[unit] || diff.days || 0;
};

// Returns a positive number if the date is in the future
// and a negative number if the date is in the past
const diffFromToday = (date: string, unit: UnitType = 'days') => {
  const today = getCurrentDate();
  const dateTime = toDateTime(date);
  const diff = (dateTime.diff(today, unit)).toObject();
  return diff?.days;
};

const extractDatePartFromISO = (dateTime: DateTime) => (dateTime.isValid && (dateTime.toISO().split('T')[0])
  ? dateTime.toISO().split('T')[0]
  : null);

export {
  getCurrentDate,
  toOrdinal,
  toSpokenDateFromISO,
  toDateString,
  toDateStringWithTime,
  toDateTime,
  toDateTimeFromMs,
  diffFromDates,
  diffFromToday,
  convertDateFormat,
  convertFromISOToFormat,
  convertFromFormatToISO,
  extractDatePartFromISO,
};
