import { Typography, Container } from '@mui/material';
import { Component } from 'react';
import { styled } from '@mui/material/styles';
import StandardLink from '@/components/atoms/standard-link';
import { trackComponentError } from '@/myphoenix/utils/error-functions';

export const StyledLink = styled(StandardLink)(({ theme }) => ({
  display: 'flex',
  fontWeight: 'inherit',
  color: 'inherit',
  textDecoration: 'none',
  height: '100%',
  ':focus, :hover': {
    textDecoration: 'underline',
    textDecorationColor: theme.palette.custom.brandRed,
  },
}));

type Props = {};

type State = {
  hasError: boolean
};

const dataComponentName = 'myphoenix-next-client-error';

class ErrorBoundary extends Component<Props, State> {
  constructor(props: unknown) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI
    trackComponentError(dataComponentName, { error });

    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    // You can use your own error logging service here
    trackComponentError(dataComponentName, { error, errorInfo });
  }

  render() {
    // Check if the error is thrown
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state?.hasError) {
      // You can render any custom fallback UI
      return (
        <Container maxWidth="md" data-component={dataComponentName}>
          <Typography variant="h3" component="h1" fontWeight="800" mt={6}>
            Looks like we ran into an issue.
          </Typography>
          <Typography variant="h4" component="h1">
            Let&apos;s get you to class:
          </Typography>
          <StyledLink href="https://d2l.phoenix.edu/d2l/lp/auth/saml/initiate-login?entityId=uopxBrightSpaceIDP">
            <Typography variant="body2" mt={2}>
              I&apos;m in a Direct Assessment program
            </Typography>
          </StyledLink>
          <StyledLink href="https://vle.phoenix.edu/">
            <Typography variant="body2" mt={2}>
              I&apos;m not in a Direct Assessment program
            </Typography>
          </StyledLink>
        </Container>
      );
    }

    // Return children components in case of no error
    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

export default ErrorBoundary;
