import DOMPurify from 'dompurify';

// consider the usage of a proper template library if the use-cases are extensive
export const stringFromTemplate = (template: string, data: string[]) => {
  const outputStr = template.replace(/#\{(\d)\}/g, (_, p1) => data[p1]);
  return outputStr;
};

export const resolveExternalConfigs = (
  incoming: string,
  dynamicVals: string[],
  defaultVal?: string,
) => {
  let resolved = defaultVal || '';
  if (incoming.match(/#{\d+}/)) {
    resolved = dynamicVals ? (stringFromTemplate(incoming, dynamicVals) || resolved) : resolved;
  } else if (incoming) {
    resolved = incoming;
  }
  return resolved;
};

export const makeBold = (string: string, keyword: string) => {
  const re = new RegExp(keyword, 'g');
  return string.replace(re, `<b>${keyword}</b>`);
};

export const componentNameToMyPhxKebabFormat = (componentName: string) => {
  const nameSpace = 'myphx';
  // find all capitals and add dash before value example ComponentName --> -Component-Name
  const nameDelimited = componentName?.replace(/([A-Z])/g, '-$1');
  // then format to lower case example -Component-Name  --> -component-name
  const fullyFormatted = nameDelimited?.toLowerCase();
  // full value myphx-component-name
  return nameSpace + fullyFormatted;
};

export const mergeComponentNames = (parentComponentName: string, childComponentName: string) => {
  if (parentComponentName && childComponentName) {
    return `${parentComponentName}_${childComponentName}`;
  }
  return parentComponentName || childComponentName;
};

export const mapVariableToValue = (defaultVal: string, incoming: string, variableMap?:
{ [date: string]: string }) => {
  let resolved = defaultVal;
  if (variableMap) {
    const objectKeysRegex = new RegExp(Object.keys(variableMap).join('|'), 'g');
    if (incoming.match(objectKeysRegex)) {
      resolved = incoming.replace(objectKeysRegex, (matched: string) => variableMap[matched]);
    }
  } else if (incoming) {
    resolved = incoming;
  }
  return resolved;
};

export const toTitleCase = (value: string) => {
  if (!value?.length) return '';
  const words = value.trim().toLowerCase().split(/\s+/);
  return words
    .filter((word) => !!word?.length)
    .map((word) => word[0].toUpperCase() + word.substring(1))
    .join(' ');
};

export const gradeDescribinator = (grade: string, isCBEDA: boolean) => {
  const sanitizedGrade = grade.toLowerCase();
  interface GradeMap {
    ma: string,
    me: string,
    nm: string,
    dr: string,
    w: string,
  }
  const gradeMap: GradeMap = {
    ma: 'MASTERED',
    me: 'MET',
    nm: 'NOT MET',
    dr: 'DROPPED',
    w: 'WITHDRAWN',
  };
  if (isCBEDA && gradeMap[sanitizedGrade as keyof typeof gradeMap]) {
    return gradeMap[sanitizedGrade as keyof typeof gradeMap];
  }
  return grade;
};

export const replaceCreditVerbiageIfNeeded = (
  isCBEDA: boolean,
  string :string,
  includeCompetency = false,
) => (
  isCBEDA ? string.replace(/Credit/g, `${includeCompetency ? 'Competency ' : ''}Unit`)
    .replace(/credit/g, `${includeCompetency ? 'competency ' : ''}unit`)
    : string);

export const sanitizeHtml = (str : string) => DOMPurify.sanitize(str, { ADD_ATTR: ['target'] });

export const truncateString = (stringToTruncate: string, truncateLength: number = 20) => {
  if (stringToTruncate.length < truncateLength) {
    return stringToTruncate;
  }
  return `${stringToTruncate.substring(0, truncateLength)}...`;
};
