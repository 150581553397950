import Features from '@/helpers/features';
import STATUS from '@/store/status';

const { seocsEnabled, seocsTemplateIdKey } = Features;

export const getTypeFromContext = (contexts) => {
  if (contexts?.find((context) => context?.indexOf('application_center/post_submission') > -1)) {
    return 'ENROLLMENT';
  }
  return 'REGULAR';
};

export const getApplicationIdFromWorkflow = (tasks) => tasks
  ?.find((task) => (task.outputData?.applicationId || '') !== '')?.outputData?.applicationId || null;

export const getRecentWorkflow = (workflow) => {
  // Workflow is frozen in this context so make a copy
  const clonedWorkflow = Array.isArray(workflow) ? [...workflow] : [];
  const recentApplication = clonedWorkflow
    ?.sort((current, next) => (next?.startTime || 0) - (current?.startTime || 0))?.[0];
  return {
    applicationId: getApplicationIdFromWorkflow(recentApplication?.tasks),
    workflow: recentApplication,
  };
};

export const sortTasks = (tasksList, applicationId = '', isSeocsEnabled = seocsEnabled, seocsTemplateId = seocsTemplateIdKey) => {
  const completedTasks = [];
  const assignedTasks = [];
  const enrollmentTasks = [];

  tasksList?.forEach?.((value) => {
    const task = {};
    task.title = value.title;
    task.description = value.description || '';
    task.id = value.id;
    task.templateId = value.templateId;
    task.userActionable = !!value.userActionable;
    task.status = value.states?.status?.action;
    task.link = value.link;
    task.linkTarget = value.linkTarget;
    task.name = value.name;
    task.linkCaption = value.linkCaption;
    task.dueDate = value.states?.dueDate;
    task.type = getTypeFromContext(value.contexts);
    task.updateStatus = STATUS.UNFETCHED;
    task.priority = value.priority;
    task.expiryDate = value.states?.expiryDate;

    const isSeocsTask = isSeocsEnabled ? task.templateId !== seocsTemplateId : true;

    if (task.status.toLowerCase() === 'completed') {
      completedTasks.push(task);
    } else if (task.type === 'ENROLLMENT'
      && (value.relatedId === applicationId || (value.relatedId || '') === '') && isSeocsTask) {
      task.relatedId = value.relatedId;
      enrollmentTasks.push(task);
    } else if (task.type !== 'ENROLLMENT' && isSeocsTask) {
      assignedTasks.push(task);
    }
  });
  return {
    completedTasks,
    assignedTasks,
    enrollmentTasks,
  };
};

export const getValueFromWorkflow = (workflow, taskName, key) => {
  if (workflow) {
    const submissionWorkflowTask = workflow.tasks
      .find((task) => task.referenceTaskName === taskName);
    if (submissionWorkflowTask) {
      return submissionWorkflowTask[key];
    }
  }
  return null;
};
